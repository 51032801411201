@import '../../node_modules/@my7n/ui/src/css/themes/my7n-theme/my7n-theme';
@import '../../node_modules/@my7n/ui/src/css/vendor/all';
@import 'global/html.element.scss';
@import 'global/before-angular';

*, :after, :before {
  box-sizing: border-box;
}

.my7n-theme {
  @include html-element-scroll-settings();

  @import '../../node_modules/@my7n/ui/src/css/buttons-indicators/all';
  @import '../../node_modules/@my7n/ui/src/css/data-table/all';
  @import '../../node_modules/@my7n/ui/src/css/forms/all';
  @import '../../node_modules/@my7n/ui/src/css/helpers/all';
  @import '../../node_modules/@my7n/ui/src/css/layout/all';
  @import '../../node_modules/@my7n/ui/src/css/navigation/all';
  @import '../../node_modules/@my7n/ui/src/css/pages/all';
  @import '../../node_modules/@my7n/ui/src/css/popups-modals/all';
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import '../../node_modules/@my7n/ui/src/css/tailwind/base/all';
}

@layer components {
  @import '../../node_modules/@my7n/ui/src/css/tailwind/components/all';
}

@layer utilities {
  @import '../../node_modules/@my7n/ui/src/css/tailwind/utilities/all';
}